import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppCodeInterceptor } from './app-code.interceptor';
import { ApplicationInterceptor } from './application.interceptor';
import { LanguageInterceptor } from './language.interceptor';
import { LoadingInterceptor } from './loading.interceptor';
import { LoggingInterceptor } from './logging.interceptor';
import { ManageErrorsInterceptor } from './manage-errors.interceptor';
import { OAuthInterceptor } from './o-auth.interceptor';
import { PlansErrorInterceptor } from './plans-error.interceptor';
import { QaroniErrorsInterceptor } from './qaroni-errors-interceptors.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ManageErrorsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApplicationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppCodeInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PlansErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: QaroniErrorsInterceptor,
    multi: true,
  },
];
