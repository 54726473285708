import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PlansErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const companyID: number = parseInt(this.router.url.split('/')[2]);
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          error.error.errors.forEach(error => {
            if (error.code === 'E0048') {
              this.router.navigateByUrl(
                `/companies/${companyID}/contact-your-admin`
              );
            }
          });
        }

        return throwError(error);
      })
    );
  }
}
