import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpInterceptorProviders } from './interceptors/http-interceptors-provider';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatSnackBarModule],
  exports: [HttpClientModule],
  providers: [HttpInterceptorProviders, { provide: Window, useValue: window }],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

registerLocaleData(localeEs, 'es-ES');
