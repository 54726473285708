import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesConfig } from '@qaroni-core/config/app/images.config';

@Component({
  selector: 'qaroni-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerICons();
  }

  private registerICons(): void {
    this.matIconRegistry.addSvgIcon(
      'shifts',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        ImagesConfig.shiftsIcon.url
      )
    );

    this.matIconRegistry.addSvgIcon(
      'evaluations',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        ImagesConfig.evaluationsIcon.url
      )
    );
  }
}
